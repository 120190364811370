import React from 'react';
import ArtDetails from '../../components/art/ArtDetails';
import ArtBanner from '../../components/banner/ArtBanner';
import Footer from '../../components/footer/Footer';
import bannerImg from "../../assets/Industries Hero/Real-Estate-Hero.png"
import RealEstateData from "../../assets/data/real-state.js";
import Create from '../../components/create/Create2';
import contactImg from '../../assets/Services/have-a-project2.png';

function RealEstate(props) {
    const desc = `Transform the way you showcase properties with our technology-driven solutions.  Experience a new era of immersive engagement that captivates clients and take your projects to new heights.`;
    const contactData = {
        heading: 'Ready to transform your real est﻿ate experience?',
        desc: 'Upgrade your property presentations with our futuristic tech solutions.',
        btn: true,
        target: 'blank',
        btnTitle: 'Contact our experts today!',
        link: '/contact',
        img: contactImg
    }
    const itemBtn = {
        buttonFlag: true,
        button1: true,
        btnTitle1: "Book a Call",
        link1: "https://calendly.com/raed-avr/",
        button2: true,
        btnTitle2: "Request a Quote",
        link2: "/contact",
        target: "blank"
    }
    return (
        <div className='home-2 wrapper'>
                <ArtBanner image={bannerImg} heading="Real Estate Solutions for the Future" subheading="Industries" desc={desc} itemBtn={itemBtn}/>

                <ArtDetails data={RealEstateData}/>
                <Create style={{ marginTop: "100px" }} data={contactData} />
                <Footer />
        </div>
    );
}

export default RealEstate;