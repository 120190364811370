
import icon1 from '../images/icon/quote.png'
import avt1 from '../images/layouts/avt-02.png'


const dataTestimonials = [

    {
        id: 1,
        icon: icon1,
        text: "“ Working with AVR Labs was a game-changer for Rattan House Store. They helped us provide an immersive and engaging experience for our customers through their AR & VR store. Our online presence has never been stronger, and we've seen a significant increase in customer engagement and sales. We highly recommend AVR Labs to any business looking to take their online presence to the next level.“",
        avt: avt1,
        name: 'Haitham Badran',
        position: 'CEO of Rattan House'
    },
    {
        id: 2,
        icon: icon1,
        text: "“ AVR Labs is a true pioneer in the world of XR technology. Their commitment to innovation and evolution is evident in the quality of their work. We partnered with them to create a virtual reality training program for our employees, and the results have been outstanding. Our employees are more engaged and knowledgeable than ever before, and our business has seen a positive impact on the bottom line. “",
        avt: avt1,
        name: 'Ahmad Al-Suwaidi',
        position: 'Director of the Office of Strategy and Institutional Development'
    }, 
    {
        id: 3,
        icon: icon1,
        text: "“ AVR Labs helped us bring our projects to life in an entirely new way. Their virtual reality solutions allowed our customers to interact with our projects in a way that was previously impossible. The team at AVR Labs was a pleasure to work with, and their passion for immersive technologies was contagious. We look forward to continuing to work with them to innovate and transform the interior design world. “",
        avt: avt1,
        name: 'Joseph Haddad',
        position: 'CEO of Safari Décor'
    }, 
    {
        id: 4,
        icon: icon1,
        text: `“ From our very first interaction to the final handover, the journey has been nothing short of extraordinary, filled with professionalism, innovation, and a relentless pursuit of excellence.
        Your team's ability to understand and translate my vision into a strategic plan was remarkable. The creativity and thoughtfulness in approach, coupled with an unwavering commitment to quality, have significantly contributed to the project's success. It is rare to find a partnership where dedication and passion are so evident, making complex challenges seem surmountable with ease and grace. “`,
        avt: avt1,
        name: 'Mai Alhammadi',
        position: 'Meem 48'
    }, 
    
]
export default dataTestimonials;