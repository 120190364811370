import interactiveFlyersImg from "../industries/Hospitality/Comp-2.jpg";
import findTheKitchenImg from "../industries/Hospitality/Find-the-Chicken.jpg";
import aiImg from "../industries/Hospitality/AI.jpg";
import holographicDisplaysImg from "../industries/Hospitality/Holographic Displays.jpg"
import smartStandsImg from "../industries/Hospitality/smart stands.jpg"
const vimeoVideoUrls = {
  tableMatte: "https://player.vimeo.com/video/938506208?badge=0&autopause=0&player_id=0&app_id=58479&title=0&byline=0&portrait=0",    
};
const dataItem = [
  {
    id: 1,
    img: '',
    iframe: 'https://virtualdesignshub.com/viewer/sofitel',
    iframe2:"https://tours.avrlabs.io/Cigar-Lounge/Vtour/tour.html",
    title: "Immersive Virtual Tours with Google Street View",
    desc: `<p>Offer a virtual exploration of your campus, showcasing classrooms, libraries, and special facilities with immersive 360-degree tours. Enhance the experience with Google Street View navigation, allowing seamless and intuitive exploration to attract and engage prospective students.<p>`,
    button: true,
    slug: "A08",
    btnTitle: "Start a Virtual Tour ",
    video: '',
    link: "/services/interactive-virtual-tours",
  },
  {
    id: 2,
    img: interactiveFlyersImg,
    iframe: '',
    title: "Interactive Flyers",
    backTransparent: true,
    desc: `<p> Innovate marketing with Interactive Flyers, combining traditional promotion with digital engagement. Provide guests dynamic content, exclusive offers, and interactive elements for an enhanced pre-arrival experience.<p>`,
    button: false,
    slug: "A02",
    btnTitle: "Learn more",
    video:'',
    link: "/services/extended-reality",
    scan:true
  },
  {
    id: 3,
    img: '',
    title: "AR Menu: Interactive Dish Previews",
    desc: `<p>Bring your menu to life by allowing guests to preview dishes in augmented reality, giving them a clear, mouthwatering look at what they’re about to order.<p>`,
    button: true,
    slug: "A02",
    btnTitle: "Demo of AR Menu",
    video: vimeoVideoUrls['tableMatte'],
    link: "/services/extended-reality",
  },
  {
    id: 4,
    img: aiImg,
    title: "AI Virtual Assistant for Real-Time Support",
    desc: `<p>Provide seamless, round-the-clock assistance with an AI virtual assistant that manages orders, answers queries, and offers real-time support, reducing wait times and freeing up your staff for a smoother guest experience.<p>`,
    button: true,
    slug: "S03",
    btnTitle: "Book a Demo",
    video: '',
    link: "/solutions/ai-virtual-beings",
  },
  {
    id: 5,
    img: holographicDisplaysImg,
    title: "Holographic Displays",
    desc: `<p>Captivate guests with Holographic Displays, offering visually stunning presentations, event information, and interactive content, creating a futuristic and engaging ambiance within hospitality venues.<p>`,
    button: true,
    slug: "S07",
    btnTitle: "Learn more",
    video: '',
    link: "/solutions/holographic-displays",
  },
  {
    id: 6,
    img: smartStandsImg,
    title: "Smart Stands",
    desc: `<p>Revolutionize event management in hospitality with Smart Stands. Integrate technology for interactive displays, seamless check-ins, and real-time information, enhancing efficiency and guest engagement at various functions.<p>`,
    button: true,
    slug: "S04",
    btnTitle: "Learn more",
    video: '',
    link: "/solutions/smart-stands",
  },
  {
    id: 7,
    img: findTheKitchenImg,
    title: "AR Treasure Hunt: Interactive In-Venue Adventure",
    desc: `<p>Create an exciting AR treasure hunt at your venue. Guests follow digital clues, collect points, and earn rewards, adding a layer of excitement and interaction to their visit.<p>`,
    button: true,
    slug: "S04",
    btnTitle: "Explore Kid-Friendly AR Experiences",
    video: '',
    link: "/",
  },
  
];

export default dataItem;
