import React , {useState} from 'react';
import PropTypes from 'prop-types';
import portfolioImg from '../../assets/images/layouts/portfolio1.png'

SolutionPortfolio.propTypes = {
    data: PropTypes.array,
    headerData: PropTypes.object,
    step:PropTypes.string,
    numbering:PropTypes.string,
};
SolutionPortfolio.defaultProps = {
    data:[],
    step:'true',
    numbering: 'false',
    headerData: {
        subheading:'',
        heading:'',
        desc:'',
        img: portfolioImg,
    }
};
function SolutionPortfolio(props) {
    const {data ,headerData,step, numbering} = props;
    return (
        <section className="portfolio s2">
                {/* <div className="shape"></div> */}
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="block-text center py-5">
                                {headerData.subheading && <h6 className="sub-heading"><span>{headerData.subheading}</span></h6>}
                                {headerData.heading && <h2 className="heading pd">{headerData.heading}</h2>}
                                {headerData.desc && <p dangerouslySetInnerHTML={{ __html: headerData.desc }}/>}
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-12">
                            <div className="portfolio__left">
                                <div className='row'>
                                    {
                                        data.map((idx) => (
                                            <div className='col-xl-12 col-md-6' key={idx.id}>
                                                <div className="portfolio-box">
                                                    {step == 'true' && <div className="step">Step {idx.id}</div>}
                                                    {numbering == 'true' && step == 'false' && <div className="step">{idx.id}</div>}
                                                    {idx.img && <div className="icon">
                                                        <img src={idx.img} alt="AVRLabs" />
                                                            
                                                    </div>}
                                                    <div className="content">
                                                        <h5 className="title">{idx.title}</h5>
                                                        <p dangerouslySetInnerHTML={{ __html: idx.desc }} />
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>                            
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-12">
                            <div className="portfolio__right">
                                <div className="image" data-aos="fade-left" data-aos-duration="2000">
                                    <img src={headerData.img} alt="AVRLabs" className='w-100' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    );
}

export default SolutionPortfolio;