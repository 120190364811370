import React from 'react';
import ArtDetails from '../../components/art/ArtDetails.jsx';
import ArtBanner from '../../components/banner/ArtBanner.jsx';
import Footer from '../../components/footer/Footer.jsx';
import bannerImg from "../../assets/Industries Hero/Events-Hero.jpg"
import EventsData from "../../assets/data/events.js";
import EventsThridData from "../../assets/data/events-second.js";
import interactiveActivationImg from "../../assets/industries/Events/interactive activation.jpg";
import Create from '../../components/create/Create2';
import contactImg from '../../assets/Services/have-a-project2.png';

function Events(props) {
    const desc ="Whether you’re an exhibitor seeking to captivate audiences or an organizer aiming to enhance event impact, our tailored solutions ensure your goals are met with precision and creativity.";
    
    const vimeoVideoUrls = {
        vrShowroom: "https://www.youtube.com/embed/YqA9D-9lhNs?controls=0&modestbranding=1&showinfo=0&rel=0",
        AI: "https://www.youtube.com/embed/9N_BxO0wA_Y?controls=0&modestbranding=1&showinfo=0&rel=0",
    };
    const contactData = {
        heading: 'Your Event, Powered by AVR Labs',
        desc: 'Let’s bring your vision to life. ',
        btn: true,
        target: 'blank',
        btnTitle: 'Contact us today!',
        link: '/contact',
        img: contactImg
    }
    const EventsSecondData = [
        {
          id: 1,
          img: '',
          iframe: '////',
          title: "Augmented Reality Experiences",
          desc: `<p>Showcase product usage, features, or brand history by integrating AR overlays into physical displays. For example, demonstrate how a product functions in real-world scenarios through a visitor’s smartphone.<p>`,
          button: false,
          slug: "",
          btnTitle: "",
          video: "",
          link: "",
        },
        {
          id: 2,
          img: '',
          iframe: '',
          title: "Virtual Reality Showrooms",
          desc: `<p>Offer immersive 360-degree VR tours of your factory, office, or product ecosystem, creating a strong connection without requiring physical travel.<p>`,
          button: false,
          slug: "",
          btnTitle: "",
          video: vimeoVideoUrls['vrShowroom'],
          link: "",
        },
        {
          id: 3,
          img: interactiveActivationImg,
          iframe: '',
          title: "Int﻿eractive Gamification",
          desc: `<p>Attract attention with tailored games that educate visitors about your brand while delivering prizes or special promotions.<p>`,
          button: true,
          slug: "",
          btnTitle: "Learn more",
            video: '',
            link: "/services/event-tech-and-booth-stands",
        },
    ];
    const data = {
        heading: 'Exhibitors',
        desc: `Maximize booth impact, generate quality leads, and achieve measurable outcomes.`,
    };
    const data1 = {
        heading: 'Organizers',
        desc: `Elevate the overall event experience, increase attendee satisfaction, and deliver value to stakeholders.`,
    };
    return (
        <div className='home-2 wrapper'>
            <ArtBanner image={bannerImg} heading="Events and Exhibitions Solutions" subheading="Industries" desc={desc}/>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-12 pb-5">
                            <div className="block-text center">
                                <h6 className="sub-heading">
                                    <span>
                                        Our Tailored Solutions
                                    </span>
                                </h6>
                                <h3 className="heading">Custom Booths & Exhibition Stands</h3>
                                <p>Engage visitors with interactive and innovative booth designs</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ArtDetails data={EventsData}/>

            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-12 pb-5">
                            <div className="block-text center">
                                <h6 className="sub-heading">
                                    <span>
                                        Our Tailored Solutions
                                    </span>
                                </h6>
                                <h3 className="heading">
                                    Enhanced Brand Activations
                                </h3>
                                <p>Transform your booth into a destination with technology that tells your brand story:</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ArtDetails data={EventsSecondData}/>

            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-12 pb-5">
                            <div className="block-text center">
                                <h6 className="sub-heading">
                                    <span>
                                        Our Tailored Solutions
                                    </span>
                                </h6>
                                <h3 className="heading">
                                    Visitor Engagement Tools
                                </h3>
                                <p>Keep attendees engaged and provide streamlined experiences:</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ArtDetails data={EventsThridData}/>

            <section className="token my-5 about-top-individual">
                <div className="shape"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-md-12 mb-4">
                            <div className="team-box">
                                <div className='image' style={{height: 'auto', padding: "25px"}}>
                                    {data.heading && (<h5 className="heading mb-2">{data.heading}</h5>)}
                                    {data.desc && (<p className="mb-0" dangerouslySetInnerHTML={{ __html: data.desc }} />)}
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-12">
                            <div className="team-box">
                                <div className='image' style={{height: 'auto', padding: "25px"}}>
                                    {data1.heading && (<h5 className="heading mb-2">{data1.heading}</h5>)}
                                    {data1.desc && (<p className="mb-0" dangerouslySetInnerHTML={{ __html: data1.desc }} />)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Create style={{ marginTop: "100px" }} data={contactData} />
            <Footer />
        </div>
    );
}

export default Events;