const dataTechConsultancy = [

    {
        id: 'One',
        title: 'Strategic Consultancy for Tech Success',
        desc: `Gain expert insights and guidance to confidently navigate the complexities of modern technology. Make informed decisions that align with your business goals and drive success.`
    },
    {
        id: 'Two',
        title: 'Bridging the Gap to Innovation',
        desc: `In today’s fast-paced world, every company must embrace technology to thrive. We’re here to guide you seamlessly into the future with cutting-edge solutions tailored to your needs.`
    },
    {
        id: 'Three',
        title: 'Expert Support Across Modern Tech',
        desc: `Leverage our team’s proficiency in AR, VR, AI, Blockchain, Computer Science, and the Metaverse. With over 15 years of experience, we ensure your business stays at the forefront of innovation.`
    },
    {
        id: "Four",
        title: 'Personalized Tech Strategies',
        desc: `We begin with a detailed analysis of your business to craft a tailored technology strategy. Our personalized approach ensures every solution aligns with your unique objectives for maximum impact.`
    },
]

export default dataTechConsultancy;