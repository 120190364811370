
const vimeoVideoUrls = {
    aiKisok: "https://player.vimeo.com/video/936132224?badge=0&autopause=0&player_id=0&app_id=58479&title=0&byline=0&portrait=0",
    Tengible: "https://www.youtube.com/embed/1GBpVaYEwR8?controls=0&modestbranding=1&showinfo=0&rel=0",
    AI: "https://www.youtube.com/embed/9N_BxO0wA_Y?controls=0&modestbranding=1&showinfo=0&rel=0",
};
const dataItem = [
  {
    id: 1,
    img: '',
    iframe: 'https://virtualdesignshub.com/stagingnew/viewer/index.php?code=38b3eff8baf56627478ec76a704e9b52',
    title: "Award-Winning Designs",
    desc: `<p>We specialize in designing and building custom booths and exhibition stands from scratch, creating award-winning solutions that perfectly reflect your brand and leave a lasting impression.<p>`,
    button: false,
    slug: "",
    btnTitle: "",
    video: "",
    link: "",
  },
  {
    id: 2,
    img: '',
    iframe: '',
    title: "Interactive Product Tables",
    desc: `<p>Enable visitors to browse your product catalog using a touch-enabled display that showcases videos, specifications, and interactive 3D models<p>`,
    button: false,
    slug: "",
    btnTitle: "",
    video: vimeoVideoUrls['Tengible'],
    link: "",
  },
  {
    id: 3,
    img: '',
    iframe: '',
    title: "Pick-and-Learn Screens",
    desc: `<p>Empower visitors to explore your products by simply picking them up, triggering detailed visuals and insights on nearby screens.<p>`,
    button: false,
    slug: "",
    btnTitle: "",
    video: vimeoVideoUrls['Tengible'],
    link: "",
  },
  {
    id: 4,
    img: '',
    iframe: '',
    title: "AI-Powered Brand Ambassadors",
    desc: `<p>Deploy AI kiosks with custom avatars that can answer FAQs, provide product recommendations, or deliver multilingual assistance in real time.<p>`,
    button: false,
    slug: "",
    btnTitle: "",
    video: vimeoVideoUrls['AI'],
    link: "",
  },

  
];

export default dataItem;
