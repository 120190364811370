import React from 'react';
import Banner from '../../components/banner/ServiceBanner';
import Footer from '../../components/footer/Footer';
import Create from '../../components/create/Create2';
import AboutService2 from '../../components/about/AboutService2';
import ServiceToken from '../../components/token/ServiceToken';
import ServiceToken2 from '../../components/token/ServiceToken2';
import token2DataImgFirst from '../../assets/Services/technology-consultancy/Tech consultation.png';
import token2DataImgSecond from '../../assets/Services/technology-consultancy/Visionary Insights.png';
import weAreGood from '../../assets/Services/technology-consultancy/we-good-at.png';
import personalizedSupport from '../../assets/Services/technology-consultancy/Personalized Support.png';

import SolutionPortfolio from '../../components/portfolio/SolutionPortfolio';
import portfolioImg from '../../assets/Services/technology-consultancy/What We Offer.png'
import dataTechnologyConsultancy from '../../assets/data/data-tech-portfolio';

import dataBox from '../../assets/data/data-Box-Tech-Con';
import SolutionSpeciality from '../../components/speciality/SolutionSpeciality';

import contactImg from '../../assets/Services/have-a-project2.png';

function TechnologyConsultancy(props) {
    const bannerData = {
        heading: 'Technology Consultancy & Workshop',
        desc: "Are you ready to redefine your business approach with technology?",
        button: false,
        btnTitle: "Talk To Our XR Experts",
        link: '/contact',
        images:[],
    };
    const token2DataFirst = {
        heading: '',
        desc: `<p class="mb-4">Get hands-on experiences and practical insights that will reshape your career trajectory.</p>
        <p class="mb-4">We believe in more than just guidance; we believe in transformation.</p>
        <p class="mb-4">Our Consultancy and Advisory Services, along with Workshops and Training Programs are designed to equip professionals like you with the tools and techniques needed to thrive in the digital age.</p>
        <p class="mb-3">We are your partners in progress!</p>`,
        btn: false,
        btnTitle: "But Token",
        link: '',
        iframe: '',
        video: '',
        img: token2DataImgFirst,
        isShape: false
    }

    const portfolioHeaderData = {
        img: portfolioImg,
        heading: 'What We Offer',
    };

    const SpecialityData = {
        heading: 'Specialized Workshops and Training',
        desc: "Learn new skills and stay ahead with hands-on workshops designed for you and your team.",
        cls: "col-xl-4 col-md-6"
    };

    const token2DataSecond = {
        heading: 'Visionary Insights',
        desc: `<p class="mb-2">Dive deep into interactive learning experiences that go beyond the surface. Our workshops provide real-world insights and practical strategies that empower you to succeed.</p>`,
        btn: false,
        btnTitle: "But Token",
        link: '',
        iframe: '',
        video: '',
        img: token2DataImgSecond,
        isShape: false
    }

    const dataPersonalizedSupport = {
        heading: 'Personalized Support',
        desc: `<p class="mb-2">Our team of experts with 15+ years experience of industry are here to support you every step of the way. Whether you need technical guidance or strategic advice, we provide personalized support that meets your unique needs.</p>`,
        btn: false,
        btnTitle: "But Token",
        link: '',
        iframe: '',
        video: '',
        img: personalizedSupport,
        isShape: false
    }

    const aboutservice2Data2 = {
        heading: '',
        subheading: "",
        desc: "",
        btn: false,
        btnTitle: "More About Us",
        link: '/about',
        img: weAreGood
    };

    const contactData = {
        heading: '',
        desc: 'Ready to enhance your skills and transform your approach to architecture and design?',
        btn: true,
        target: 'blank',
        btnTitle: 'Contact our experts today!',
        link: 'https://calendly.com/raed-avr/',
        img: contactImg
    }
    return (
        <div className='home-2 wrapper'>
            <Banner data={bannerData} />
            <ServiceToken2 data={token2DataFirst} />
            <div className='main-sec-no-padd'>
                <SolutionPortfolio headerData={portfolioHeaderData} data={dataTechnologyConsultancy} step='false' numbering='true' />
            </div>
            <SolutionSpeciality data={dataBox} headerData={SpecialityData} />
            <ServiceToken data={token2DataSecond} />

            <AboutService2 data={aboutservice2Data2} />

            <ServiceToken2 data={dataPersonalizedSupport} />

            <Create style={{ marginTop: "100px" }} data={contactData} />
            <Footer />
        </div>
    );
}

export default TechnologyConsultancy;