import React from 'react';
import ArtDetails from '../../components/art/ArtDetails.jsx';
import ArtBanner from '../../components/banner/ArtBanner.jsx';
import Footer from '../../components/footer/Footer.jsx';
import bannerImg from "../../assets/Industries Hero/Hospitality-Hero.jpg"
import HospitalityData from "../../assets/data/hospitality.js";
import Button from "../../components/button/Button";
import gameGif from '../../assets/Services/interactive-games/Snapchat lens gif.gif';
import snapApp from '../../assets/Services/interactive-games/Snapchat logo.png';
import gameQR from '../../assets/Services/interactive-games/gameQR.png';

function Hospitality(props) {
    const desc ="Deliver unforgettable experiences that blend innovation with convenience. Explore our range of hospitality solutions with innovative technology integration, designed to captivate guests, boost satisfaction, and drive repeat visits.";
    const itemBtn = {
        buttonFlag: true,
        button1: true,
        btnTitle1: "Book a Call",
        link1: "https://calendly.com/raed-avr/",
        button2: true,
        btnTitle2: "Request a Quote",
        link2: "/contact",
        target: "blank"
    }
    return (
        <div className='home-2 wrapper'>
            <ArtBanner image={bannerImg} heading="Transform Your Hospitality Experience with XR/MR Solutions" subheading="Industries" desc={desc} itemBtn={itemBtn}/>
            <ArtDetails data={HospitalityData}/>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-12 pb-5">
                            <div className="block-text center">
                                <h3 className="heading">Custom Snapchat Lens</h3>
                                <p>Boost your social media presence with a Snapchat lens unique to your venue. Guests capture and share their experiences, generating organic buzz and attracting new customers.s</p>
                                <Button title="Get Your Customized Snapchat Lens" link="/" target="blank" />
                            </div>
                        </div>
                    </div>
                    <div className="row text-center align-items-center">
                        <div className="col-xl-5 col-md-5 col-sm-5 col-12 mb-4 d-flex justify-content-center postion-relative">
                            <img src={gameGif} alt="AVRlabs Game" className="mxw-100" />
                        </div>
                        <div className="col-xl-4 col-md-4 col-sm-4 col-12 mb-4 d-flex justify-content-center postion-relative">
                            <div>
                                <img src={snapApp} alt="AVRlabs Game" className="mxw-100" />
                                <h6 className='mt-3'>Try this Snapchat Lens</h6>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-3 col-sm-3 col-12 d-flex justify-content-center">
                            <img src={gameQR} alt="AVRlabs Game" className="mxw-100" />
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    );
}

export default Hospitality;