import img1 from "../Services/technology-consultancy/icons/specializedworkshopsicon.png";
import img2 from "../Services/technology-consultancy/icons/ComprehensiveTraining.png";
import img3 from "../Services/technology-consultancy/icons/WideRangeOfTopics.png";
import img4 from "../Services/technology-consultancy/icons/HandsOnLearning.png";
import img5 from "../Services/technology-consultancy/icons/EmpowerYourTeam.png";
import img6 from "../Services/technology-consultancy/icons/EmpowerYourTeam.png";

const dataBoxTechCon = [
  {
    id: 1,
    img: img1,
    title: "Learn Virtual Design Tools",
    desc: "Understand how to use advanced design software easily and effectively.",
  },
  {
    id: 2,
    img: img2,
    title: "Explore AI Technology",
    desc: "Discover simple ways to use AI to improve your projects and save time.",
  },
  {
    id: 3,
    img: img3,
    title: "Practical Learning",
    desc: "Get hands-on experience you can apply directly to your work.",
  },
  {
    id: 4,
    img: img4,
    title: "Tailored Topics",
    desc: "Pick from a variety of workshops that fit your industry and goals.",
  },
  {
    id: 5,
    img: img5,
    title: "Boost Team Skills",
    desc: "Help your team grow with the latest tools and techniques.",
  },
  {
    id: 6,
    img: img6,
    title: "Stay Competitive",
    desc: "Keep up with new technology and lead in your field.",
  },
];

export default dataBoxTechCon;
