import virtualYearbookImg from "../images/item/item-01.png";
import virtual from "../industries/ecommerce/virtual try on.jpg";
const vimeoVideoUrls = {
    arviewz: "https://player.vimeo.com/video/935758551?badge=0&autopause=0&player_id=0&app_id=58479&title=0&byline=0&portrait=0",    
    aiChatBot: "https://www.youtube.com/embed/9N_BxO0wA_Y?controls=0&modestbranding=1&showinfo=0&rel=0",    
};
const dataItem = [
  {
    id: 1,
    img: '',
    iframe: '',
    title: "AR Viewz",
    desc: `<p>Enhance e-commerce with AR Viewz, providing customers a virtual preview of products in their physical space, revolutionizing the online shopping experience through interactive and immersive visualizations.<p>`,
    button: false,
    slug: "",
    btnTitle: "",
    video: vimeoVideoUrls['arviewz'],
    link: "",
  },
  {
    id: 2,
    img: '',
    iframe: 'https://rattan-house.store/',
    title: "Virtual E-Commerce",
    desc: `<p>Explore a digital marketplace with Virtual E-Commerce, offering users an immersive online shopping experience where they can virtually browse, interact, and make informed purchase decisions in a dynamic environment.<p>`,
    button: false,
    slug: "",
    btnTitle: "",
    video:'' ,
    link: "",
  },
  {
    id: 3,
    img: '',
    title: "AI Chat-bot",
    desc: `<p>Elevate customer service in e-commerce with an AI Chat-bot. Responsive and efficient, it provides instant support, answers queries, and enhances the overall shopping experience through personalized and automated interactions.<p>`,
    button: true,
    slug: "S03",
    btnTitle: "Learn more",
    video: vimeoVideoUrls['aiChatBot'],
    link: "/solutions/ai-virtual-beings",
  },
  {
    id: 4,
    img: virtual,
    title: "Virtual Try-on",
    desc: `<p>Transform online fashion retail with Virtual Try-on. Customers can virtually try on clothing and accessories, facilitating confident and informed purchase decisions, enriching the digital shopping journey.<p>`,
    button: true,
    slug: "A02",
    btnTitle: "Learn more",
    video: '',
    link: "/services/extended-reality",
  },
  {
    id: 5,
    img: '',
    iframe: 'https://cw.avrlabs.io/texture/',
    title: "Product Configurator",
    desc: `<p>Customize products seamlessly with a Product Configurator in e-commerce. Empower customers to personalize their purchases, visualizing variations and features, creating a tailored and engaging shopping experience.<p>`,
    button: false,
    slug: "",
    btnTitle: "",
    video: '',
    link: "",
  },
];

export default dataItem;
