import React from 'react';
import ArtDetails from '../../components/art/ArtDetails.jsx';
import ArtBanner from '../../components/banner/ArtBanner.jsx';
import Footer from '../../components/footer/Footer.jsx';
import bannerImg from "../../assets/Industries Hero/Education-Hero.jpg"
import EducationData from "../../assets/data/education.js";
import Create from '../../components/create/Create2';
import contactImg from '../../assets/Services/have-a-project2.png';

function Education(props) {
    const desc = `Enhance learning experiences with our technology-driven XR solutions designed to engage students and elevate educational outcomes. Discover how immersive technology can bring knowledge to life in schools and universities.`;
    const contactData = {
        heading: 'Ready to Enhance Learning Experiences?',
        desc: 'Contact us today to bring immersive technology to your educational institution!',
        btn: true,
        target: 'blank',
        btnTitle: 'Contact our experts today!',
        link: '/contact',
        img: contactImg
    }
    const itemBtn = {
        buttonFlag: true,
        button1: true,
        btnTitle1: "Book a Call",
        link1: "https://calendly.com/raed-avr/",
        button2: true,
        btnTitle2: "Request a Quote",
        link2: "/contact",
        target: "blank"
    }
    return (
        <div className='home-2 wrapper'>
                <ArtBanner image={bannerImg} heading="Transform Education with Innovative XR Solutions" subheading="Industries" desc={desc} itemBtn={itemBtn}/>
                <ArtDetails data={EducationData}/>
                <Create style={{ marginTop: "100px" }} data={contactData} />
                <Footer />
        </div>
    );
}

export default Education;