import React from 'react';

import PageTitle from '../components/pagetitle/PageTitle';
import AboutService2 from '../components/about/AboutService2';
import Team from '../components/team/AboutTeam';
import dataTeam from '../assets/data/dataTeam';
import Footer from '../components/footer/Footer';

import Button from '../components/button/Button';
import about1Img from '../assets/about/About-us.jpg';
import dataImg from '../assets/about/Shape04.png';
import data1Img from '../assets/about/Shape06.png';
import portfolioImg from '../assets/about/portfolio.jpg';
import SolutionPortfolio from '../components/portfolio/SolutionPortfolio';
import aboutportfolio from '../assets/data/data-about-portfolio';
import { Navigation, Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import dataPartner from '../assets/data/dataPartner'
import SolutionFaqs from '../components/faqs/SolutionFaqs';
import safriImg from '../assets/about/Safari.png';
import cityImg from '../assets/about/CityTimber.png';

function About(props) {

    function handleClick(event) {
        event.preventDefault();
    }
    const portfolioHeaderData = {
        img: portfolioImg,
        heading: 'Because We Can...',
        subheading:'WHY AVR LABS?',
        desc1:'At AVR Labs, we are dedicated to providing our clients with cutting-edge XR solutions and services that help them stand out in the digital world. Here are just a few reasons why you should choose AVR Labs as your XR partner'
    };
    const aboutus = {
        heading: 'Unleashing The Power Of XR Technology',
        subheading: "",
        desc: "AVR Labs, is a revolutionary Augmented, Virtual Reality, and Metaverse company based in Dubai with a commitment to contributing to the UAE’s vision for innovation and evolution. We offer XR solutions and services to help companies solidify their online presence and promote their product and services in an immersive and engaging way.",
        desc3: 'We believe in the power of immersive technologies and their ability to transform and revolutionize the business world.',
        btn: false,
        btnTitle: "More About Us",
        link: '/about',
        img: about1Img
    };
    const data = {
        heading: 'Mission',
        desc: `At AVR Labs, our mission is to empower businesses to leverage the full potential of XR technology. We strive to create innovative and immersive AR & VR solutions that transform the way industries operate, while reducing their impact on the environment. Our goal is to enable businesses to bring their products and services to people's environments using AR and provide unforgettable experiences with VR.`,
        img: dataImg,
    };
    const data1 = {
        heading: 'Vision',
        desc: `To create a sustainable future by revolutionizing the way businesses operate through the power of XR technology. We aim to be the leading provider of XR solutions that make a positive impact on society and the environment.`,
        img: data1Img,
    };

    const safriData = {
        heading: 'Safari Décor LLC',
        desc: `Established in 1994, Safari Décor LLC is a leader in innovative interior design and fit-out solutions. Specializing in luxury residential and commercial spaces, hotels, shop fittings, and custom joinery, their talented team transforms visions into reality. Safari Décor’s commitment to quality and creativity ensures exceptional results for every project.`,
        img: safriImg,
        btn: true,
        btnTitle: "Discover Safari Décor",
        link: "/"
    };
    const cityData = {
        heading: 'City Timber LLC',
        desc: `For over two decades, City Timber LLC has been a trusted name in the UAE's veneer industry, offering premium wood veneer solutions since 2000. As one of the first three veneer suppliers in the region, City Timber provides unparalleled quality with a vast inventory of natural and reconstituted wood veneers, marquetry, wooden inlay, and more. With rapid delivery, experienced staff, and competitive pricing, they serve over 800 companies across the UAE and GCC.`,
        img: cityImg,
        btn: true,
        btnTitle: "Explore City Timber",
        link: "/"
    };

    const teamHeaderData={
        subheading:'OUR TEAM',
        heading:'Our Professionals'
    };
    return (
        <div className='page-about wrapper pt-6'>

            <PageTitle title='About us' />

            <section className="">
                <AboutService2 data={aboutus} />
            </section>
        
            <section className="token my-5 about-top-individual">
                <div className="shape"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-md-12 mb-4">
                            <div className="team-box">
                                <div className='image' style={{height: 'auto', padding: "25px"}}>
                                    {data.img && (<img src={data.img} className="p-0 mb-2" alt={data.heading}/>)}
                                    {data.heading && (<h5 className="heading mb-2">{data.heading}</h5>)}
                                    {data.desc && (<p className="mb-0" dangerouslySetInnerHTML={{ __html: data.desc }} />)}
                                    {data.btn && (<Button title={data.btnTitle} link={data.link} />)}
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-12">
                            <div className="team-box">
                                <div className='image' style={{height: 'auto', padding: "25px"}}>
                                    {data1.img && (<img className='mb-2' src={data1.img} alt={data1.heading}/>)}
                                    {data1.heading && (<h5 className="heading mb-2">{data1.heading}</h5>)}
                                    {data1.desc && (<p className="mb-0" dangerouslySetInnerHTML={{ __html: data1.desc }} />)}
                                    {data1.btn && (<Button title={data1.btnTitle} link={data1.link} />)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <SolutionPortfolio headerData={portfolioHeaderData} data={aboutportfolio} step="false"  /> */}
            <SolutionFaqs dataBlock={portfolioHeaderData} data={aboutportfolio}  />
            <Team data={dataTeam} headerData={teamHeaderData}/>
            <section className="partner">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="block-text center">
                                <h3 className="heading">We are partnered with top brands</h3>
                            </div>

                            <Swiper
                                className="brands-swiper"
                                spaceBetween={30}
                                modules={[Pagination,Autoplay]}
                                autoplay={{
                                    delay: 3000,
                                    disableOnInteraction: false,
                                }}                              
                                breakpoints={{
                                    0: {
                                        slidesPerView: 2,
                                    },
                                    768: {
                                        slidesPerView: 4,
                                    },
                                    991: {
                                        slidesPerView: 6,
                                    },
                                }}
                                loop={true}

                            >
                                {
                                    dataPartner.map(idx => (
                                        <SwiperSlide key={idx.id}>
                                            <a href="#" onClick={handleClick}><img src={idx.img} alt="AVRLabs"/></a>
                                        </SwiperSlide>
                                    ))
                                }

                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>

            <section className="token my-5 about-top-individual">
                <div className="shape"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-12 pb-5">
                            <div className="block-text center">
                                <h3 className="heading">
                                    Our Sister Companies
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-6 col-md-12 mb-4">
                            <div className="team-box1">
                                <div className='image' style={{height: 'auto', padding: "25px"}}>
                                    {safriData.img && (<img src={safriData.img} className="p-0 mb-4" style={{width: "130px"}} alt={safriData.heading}/>)}
                                    {safriData.heading && (<h5 className="heading mb-2">{safriData.heading}</h5>)}
                                    {safriData.desc && (<p className="mb-3" dangerouslySetInnerHTML={{ __html: safriData.desc }} />)}
                                    {safriData.btn && (<Button title={safriData.btnTitle} link={safriData.link} />)}
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-12">
                            <div className="team-box1">
                                <div className='image' style={{height: 'auto', padding: "25px"}}>
                                    {cityData.img && (<img className='mb-4' src={cityData.img} style={{width: "130px"}} alt={cityData.heading}/>)}
                                    {cityData.heading && (<h5 className="heading mb-2">{cityData.heading}</h5>)}
                                    {cityData.desc && (<p className="mb-3" dangerouslySetInnerHTML={{ __html: cityData.desc }} />)}
                                    {cityData.btn && (<Button title={cityData.btnTitle} link={cityData.link} />)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />

        </div>
    );
}

export default About;